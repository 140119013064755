<template>
  <div class="footerRoot">
    <div class="footerContent">
      <div class="flexContent">
        <div class="fontSizeAuto">豫建中心经河南省民政厅审批成立，在建筑业服务多年，坚持“工学结合、知行合一、德技兼备”的理念为行业做出了巨大贡献。我们将以专业、严谨的工匠精神推动行业发展，与行业共同进步。</div>
        <div>
          <div style="display:flex">
            <div><i class="el-icon-phone"></i></div>
            <div>
              <div>0371-86541037</div>
              <div>13103832189(学习咨询)</div>
              <div>15093165750(技术咨询)</div>
            </div>
          </div>
          <div><i class="el-icon-location"></i>河南省-郑州市-金水区 豫博大厦东塔909</div>
        </div>
        <div>
          <div style="text-align:center">
            <i class="iconfont icon-iconweixingongzhonghao">&#xe612;</i>扫码关注公众号
          </div>
          <div class="flexContent">
            <div class="textCenter">
              <el-image style="width: 100px; height: 100px;padding:10px;border:1px solid #484747" :src="require('@assets/img/Code/wx_code_study.png')" fit="fill" :preview-src-list="[require('@assets/img/Code/wx_tell_study.png')]"></el-image>
              <div>河南省预拌混凝土</div>
            </div>
            <div class="textCenter">
              <el-image style="width: 100px; height: 100px;padding:10px;border:1px solid #484747" :src="require('@assets/img/Code/wx_code.png')" fit="fill" :preview-src-list="[require('@assets/img/Code/wx_code.png')]"></el-image>
              <div>豫建建筑工程技术平台</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="footerContent codeFooter">© 豫建建筑工程技术服务中心 <br class="phoneShow" />ICP备案许可: <a style="color:blue" href="http://beian.miit.gov.cn/">豫ICP备18005468号-2</a></div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import HeaderCell from "@/components/HeaderCell.vue";
import FooterCell from "@/components/FooterCell.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "FooterCell",
  components: {
    HeaderCell,
    FooterCell,
  },
  setup(props, content) {
    const store = useStore();
    return {};
  },
});
</script>
<style lang="less" scoped>
.footerRoot {
  color: #a2a3a7;
  .fontSizeAuto {
    text-indent: 2em;
    font-size: 16px;
  }
  .footerContent {
    width: @mainWidth;
    height: inherit;
    margin: 0 auto;
    padding: 10px 0;
    border-bottom: 1px solid #6c6d70;
    &:last-child {
      border-bottom: none;
    }
    .el-icon-phone,
    .el-icon-location,
    .icon-iconweixingongzhonghao {
      color: @orangeColor;
      margin-right: 10px;
    }
  }
  .textCenter {
    text-align: center;
    line-height: 20px;
  }
  .flexContent {
    display: flex;
    line-height: 40px;
    justify-content: space-between;
    & > div {
      flex: 1;
      padding: 0 35px;
    }
  }
  .codeFooter {
    text-align: center;
  }
  .phoneShow {
    display: none;
  }
}
/**窄屏自动居中 */
@media (max-width: 600px) {
  .footerRoot {
    width: 100vw;
    .footerContent {
      width: 100vw;
    }
    .flexContent {
      flex-direction: column;
    }
  }
  .phoneShow {
    display: block !important;
  }
}
</style>