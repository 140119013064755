
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "sliderIcon",
  setup(props, content) {
    const store = useStore();
    function backTop() {
      let timer = setInterval(() => {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let ispeed = Math.floor(-scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollTop + ispeed;
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    }
    return {
      backTop,
    };
  },
});
