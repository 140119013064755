
import { ref, defineComponent, computed, onBeforeRouteEnter } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "NavMenuCell",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let showNav = ref(false);

    let navIndex = computed(() => {
      if (route.path == "/index") {
        return "1";
      } else if (route.path == "/tellMe") {
        return "4";
      } else if (route.path == "/news" || route.path == "/news/detail") {
        return "2";
      } else if (route.path == "/about/company") {
        return "3";
      } else if (route.path == "/file") {
        return "5";
      }
    });
    const navList = [
      {
        index: "1",
        label: "首页",
        url: "/",
      },
      {
        index: "2",
        label: "新闻中心",
        url: "/news?page=1&key=",
      },
      {
        index: "5",
        label: "文件汇总",
        url: "/file",
      },
      {
        index: "3",
        label: "关于我们",
        url: "/about/company",
      },
      {
        index: "4",
        label: "联系我们",
        url: "/tellMe",
      },
    ];
    const clientWidth = store.state.clientWidth;
    function routeTo(item) {
      router.push(item.url);
      showNav.value = false;
    }
    return {
      navList,
      navIndex,
      routeTo,
      clientWidth,
      showNav,
    };
  },
});
