<template>
  <div class="sliderIconList">
    <!-- <div class="QQ icon">
      <i class="iconfont icon-iconQQ">&#xe7e7;</i>
      <div class="content">
        <div class="left">
          <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=921306770&site=qq&menu=yes"><img border="0" src="http://wpa.qq.com/pa?p=2:921306770:53" alt="您好！" title="您好！"/></a>
        </div>
        <img class="right" src="~@assets/img/arrow-r.png" alt="">
      </div>
    </div>
    <div class="phone icon">
      <i class="el-icon-phone-outline"></i>
      <div class="content">
        <div class="left">
          <div class="cell"><i class="el-icon-phone-outline"></i>
            <div>
              <div class="tel">0371-86541037</div>
              <div>豫建中心</div>
            </div>
          </div>
          <el-divider style="margin:10px 0"></el-divider>
          <div class="cell">
            <i class="el-icon-phone-outline"></i>
            <div>
              <div class="tel">13103832189</div>
              <div>学习咨询</div>
            </div>
          </div>
          <el-divider style="margin:10px 0"></el-divider>
          <div class="cell">
            <i class="el-icon-phone-outline"></i>
            <div>
              <div class="tel">15138685710</div>
              <div>技术咨询</div>
            </div>
          </div>
        </div>
        <img class="right" src="~@assets/img/arrow-r.png" alt="">
      </div>
    </div>
    <div class="code icon">
      <i class="iconfont icon-iconweixin">&#xe64f;</i>
      <div class="content">
        <div class="left">
          <img style="width:120px" src="~@assets/img/Code/wx_tell_study.png" alt="">
          <div>学习咨询</div>
          <img style="width:120px" src="~@assets/img/Code/wx_tell.png" alt="">
          <div>技术咨询</div>
        </div>
        <img class="right" src="~@assets/img/arrow-r.png" alt="">
      </div>
    </div>
    <div class="top icon">
      <i class="el-icon-top" @click="backTop"></i>
    </div> -->
    <div class="rightCodeContent">
        <div class="left">
          <img style="width:120px" src="~@assets/img/Code/wx_tell_study.png" alt="">
          <div>学习咨询(商混)</div>
          <img style="width:120px" src="~@assets/img/Code/study_test_code.jpg" alt="">
          <div>学习咨询(检测)</div>
          <img style="width:120px" src="~@assets/img/Code/wx_tell.png" alt="">
          <div>技术咨询</div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "sliderIcon",
  setup(props, content) {
    const store = useStore();
    function backTop() {
      let timer = setInterval(() => {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let ispeed = Math.floor(-scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollTop + ispeed;
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    }
    return {
      backTop,
    };
  },
});
</script>
<style lang="less" scoped>
@iconWidth: 50px;
@iconHeight: 50px;
.sliderIconList {
  position: fixed;
  right: 100px;
  top: 230px;
  .phone{
      .cell{
          display: flex;
          align-items: center;
          i{
              color: @orangeColor;
              margin-right: 20px;
          }
          &>div{
              text-align: left;
              white-space: nowrap;
              .tel{
                  color:@orangeColor;
              }
          }
      }
  }
  & > .icon {
    width: @iconWidth;
    height: @iconHeight;
    line-height: @iconHeight;
    text-align: center;
    border: 1px solid #ccc;
    background: white;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    /deep/ i {
      line-height: inherit;
      font-size: 30px;
      cursor: pointer;
      color: @mainColor;
    }
    &:hover {
      color: @orangeColor;
      i{
        color: @orangeColor;
      }
      .content {
        color: black;
        display: block;
      }
    }
    .content {
     line-height: 20px;
      display: none;
      position: absolute;
      top: 0;
      right: 48px;
      padding-right: 14px;
      .left {
        padding: 10px;
        background: white;
        border: 1px solid #ccc;
        border-top: 4px solid @orangeColor;
      }
      .right {
        position: absolute;
        right: 0;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .rightCodeContent{
    text-align: center;
    border-top: 4px solid @orangeColor;
    background: #f5f3f3;
    padding: 5px;
    color: #2f79c3;
    img{
      margin-top: 10px;
    }
  }
}

@media (max-width: 600px) {
  .sliderIconList {
    display: none;
    // bottom:100px !important;
    // & >div{
    //   position:initial;
    //   width: 30px !important;
    //   height: 30px !important;
    //   line-height:  30px !important;
    //   .content{
    //     right: 24px !important;
    //   }
    // }
    // .code{
    //   img{
    //     width: 80px !important;
    //   }
    // }
    // .left{
    //   padding: 5px !important;
    // }
    // .right{
    //   display: none;
    // }
  }
}
</style>