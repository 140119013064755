
import { ref, defineComponent, computed, watch } from "vue";
import HeaderCell from "@/components/HeaderCell.vue";
import FooterCell from "@/components/FooterCell.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "FooterCell",
  components: {
    HeaderCell,
    FooterCell,
  },
  setup(props, content) {
    const store = useStore();
    return {};
  },
});
