import { createRouter, createWebHashHistory, RouteRecordRaw, scrollBehavior } from 'vue-router'
import store from "../store/index";

const routes = [
  {
    path: '/',
    redirect: '/index',
    meta: {
      public: true
    }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/news.vue')
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    component: () => import('@/views/news/detail.vue')
  },
  {
    path: '/tellMe',
    name: 'tellMe',
    component: () => import('@/views/tellMe/tellMe.vue')
  },
  {
    path: '/about/company',
    name: 'company',
    component: () => import('@/views/about/company.vue')
  },
  {
    path: '/file',
    name: 'file',
    component: () => import('@/views/file/fileList.vue')
  },
  {
    path:'/404',
    name:'404',
    component: () => import('@/components/404.vue')
  },
  {
    path:'/:catchAll(.*)',
    redirect: '/404'
  }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})
router.afterEach((to, from) => {
  let bodySrcollTop = document.body.scrollTop
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0
    return
  }
  let docSrcollTop = document.documentElement.scrollTop
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0
  }
})
export default router
