
import { ref, defineComponent, computed, watch } from "vue";
import HeaderCell from "@/components/HeaderCell.vue";
import FooterCell from "@/components/FooterCell.vue";
import SliderIcon from "@/components/SliderIcon.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "App",
  components: {
    HeaderCell,
    FooterCell,
    SliderIcon
  },
  setup(props, content) {
    const store = useStore();
    const keepLiveRoute = computed(() => {
      return store.state.keepLiveRoute;
    });
    const clientWidth = ref(document.documentElement.clientWidth);
    store.commit("setClientWidth", clientWidth);
    return {
      keepLiveRoute,
    };
  },
});
