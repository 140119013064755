import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store/index'
import '@assets/css/uiColor/index.css'
import '@assets/css/theme.less'
import { ElButton, ElImage, ElContainer, ElHeader, ElMain, ElFooter, ElMenu, ElMenuItem, ElSubmenu, ElCarousel, ElCarouselItem, ElCard, ElLink, ElTabPane, ElTabs, ElDivider, ElPagination, ElRow, ElCol, ElInput, ElForm, ElFormItem, ElLoading, ElAutocomplete, ElEmpty, ElTooltip} from 'element-plus';

import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/lib/theme-chalk/display.css';
createApp(App).use(store).use(router)
    .component(ElButton.name, ElButton)
    .component(ElImage.name, ElImage)
    .component(ElContainer.name, ElContainer)
    .component(ElHeader.name, ElHeader)
    .component(ElMain.name, ElMain)
    .component(ElFooter.name, ElFooter)
    .component(ElMenu.name, ElMenu)
    .component(ElMenuItem.name, ElMenuItem)
    .component(ElSubmenu.name, ElSubmenu)
    .component(ElCarousel.name, ElCarousel)
    .component(ElCarouselItem.name, ElCarouselItem)
    .component(ElCard.name, ElCard)
    .component(ElLink.name, ElLink)
    .component(ElTabs.name, ElTabs)
    .component(ElTabPane.name, ElTabPane)
    .component(ElDivider.name, ElDivider)
    .component(ElPagination.name, ElPagination)
    .component(ElRow.name, ElRow)
    .component(ElCol.name, ElCol)
    .component(ElInput.name, ElInput)
    .component(ElForm.name, ElForm)
    .component(ElFormItem.name, ElFormItem)
    .component(ElLoading.name, ElLoading)
    .component(ElAutocomplete.name, ElAutocomplete)
    .component(ElEmpty.name, ElEmpty)
    .component(ElTooltip.name, ElTooltip)
    .mount('#app')
