<template>
  <div class="pageRoot">
    <el-container>
      <el-header>
        <HeaderCell></HeaderCell>
      </el-header>
      <el-main>
        <router-view>
        </router-view>
      </el-main>
      <el-footer>
        <FooterCell></FooterCell>
      </el-footer>
    </el-container>
    <SliderIcon></SliderIcon>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import HeaderCell from "@/components/HeaderCell.vue";
import FooterCell from "@/components/FooterCell.vue";
import SliderIcon from "@/components/SliderIcon.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "App",
  components: {
    HeaderCell,
    FooterCell,
    SliderIcon
  },
  setup(props, content) {
    const store = useStore();
    const keepLiveRoute = computed(() => {
      return store.state.keepLiveRoute;
    });
    const clientWidth = ref(document.documentElement.clientWidth);
    store.commit("setClientWidth", clientWidth);
    return {
      keepLiveRoute,
    };
  },
});
</script>
<style lang="less">
html {
  min-height: 100vh;
  background: white;
  font-family: STSong;
}
body {
  font-size: 16px;
  margin: 0;
  min-width: @mainWidth;
}
* {
  box-sizing: border-box;
}
/**窄屏 */
@media (max-width: 600px) {
  body{
    min-width: unset;
  }
}

</style>
<style lang="less" scoped>
.pageRoot {
  min-height: 100vh;
  /deep/ .el-container {
    position: relative;
    min-height: 100vh;
  }
  /deep/ .el-header {
    background: @mainColor;
    color: white;
    position: relative;
    // position: sticky;
    top: 0;
    z-index: 1999;
    height: 150px !important;
    background: linear-gradient(#0076bc,#01245c);
    box-shadow: 0 1px 100px #c5d3e1;
  }
  /deep/ .el-main {
    min-height: 630px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-footer {
    border-top: 3px solid @orangeColor;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto !important;
    background: linear-gradient(#01245c,#000000);
    padding: 10px 0;
  }
}

</style>
