<template>
  <div class="headerRoot">
    <div class="logoRoot">
      <el-image class="headerLogo" fit="fill" :src="require('@assets/img/logo.png')"></el-image>
      <span class="headerText">
        <div class="companyTitle">河南省豫建建筑工程技术服务中心</div>
        <!-- <div class="englishText phoneNone">Yu Jian Zhong Xin</div> -->
      </span>
      <i class="el-icon-s-fold phoneShow" @click="showNav = !showNav" style="font-size:35px"></i>
    </div>
    <div class="NavRoot">
      <div v-if="clientWidth >= 600" class="navContainer">
        <el-menu class="nav" :default-active="navIndex" mode="horizontal" text-color="#fff" menu-trigger="hover" active-text-color="@orangeColor">
          <template v-for="(item,index) in navList" :key="index">
            <el-menu-item @click="routeTo(item)" v-if="!item.children || item.children.length == 0" :index="item.index">{{item.label}}</el-menu-item>
            <el-submenu :index="item.index" v-else>
              <template #title>{{item.label}}</template>
              <template v-for="(aitem, aindex) in item.children" :key="aindex">
                <el-menu-item @click="routeTo(aitem)" v-if="!aitem.children || aitem.children.length == 0" :index="aitem.index">{{aitem.label}}</el-menu-item>
                <el-submenu :index="aitem.index" v-else>
                  <template #title>{{aitem.label}}</template>
                  <template v-for="(bitem, bindex) in aitem.children" :key="bindex">
                    <el-menu-item @click="routeTo(bitem)" :index="bitem.index">{{bitem.label}}</el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <div v-else>
        <el-menu class="phoneNav" :style="{height:showNav?'280px':'0px'}" :default-active="navIndex" mode="vertical" text-color="#fff" menu-trigger="hover" active-text-color="#409EFF">
          <template v-for="(item,index) in navList" :key="index">
            <el-menu-item @click="routeTo(item)" v-if="!item.children || item.children.length == 0" :index="item.index">{{item.label}}</el-menu-item>
            <el-submenu :index="item.index" v-else>
              <template #title>{{item.label}}</template>
              <template v-for="(aitem, aindex) in item.children" :key="aindex">
                <el-menu-item @click="routeTo(aitem)" v-if="!aitem.children || aitem.children.length == 0" :index="aitem.index">{{aitem.label}}</el-menu-item>
                <el-submenu :index="aitem.index" v-else>
                  <template #title>{{aitem.label}}</template>
                  <template v-for="(bitem, bindex) in aitem.children" :key="bindex">
                    <el-menu-item @click="routeTo(bitem)" :index="bitem.index">{{bitem.label}}</el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed, onBeforeRouteEnter } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "NavMenuCell",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let showNav = ref(false);

    let navIndex = computed(() => {
      if (route.path == "/index") {
        return "1";
      } else if (route.path == "/tellMe") {
        return "4";
      } else if (route.path == "/news" || route.path == "/news/detail") {
        return "2";
      } else if (route.path == "/about/company") {
        return "3";
      } else if (route.path == "/file") {
        return "5";
      }
    });
    const navList = [
      {
        index: "1",
        label: "首页",
        url: "/",
      },
      {
        index: "2",
        label: "新闻中心",
        url: "/news?page=1&key=",
      },
      {
        index: "5",
        label: "文件汇总",
        url: "/file",
      },
      {
        index: "3",
        label: "关于我们",
        url: "/about/company",
      },
      {
        index: "4",
        label: "联系我们",
        url: "/tellMe",
      },
    ];
    const clientWidth = store.state.clientWidth;
    function routeTo(item) {
      router.push(item.url);
      showNav.value = false;
    }
    return {
      navList,
      navIndex,
      routeTo,
      clientWidth,
      showNav,
    };
  },
});
</script>
<style lang="less" scoped>
.headerRoot {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: space-between;
  width: @mainWidth;
  max-width: @mainWidth;
  margin: 0 auto;
  width: 100%;
  .logoRoot {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: space-between;
    .headerLogo {
      width: 60px;
    }
    .headerText {
      margin-left: 10px;
      .companyTitle {
        letter-spacing: 1px;
        font-size: 35px;
      }
      .englishText {
        color: @greyColor;
      }
    }
  }

  .navContainer {
    margin: 0 auto;
    width: 100%;
    /deep/ .el-menu {
      border: none !important;
      .el-menu-item {
        &:hover {
          background: #318ce8 !important;
          color: white !important;
        }
      }
    }

    /deep/ .is-active {
      background: #318ce8 !important;
      color: #62b364;
    }
    .nav {
      margin: 0 auto;
      width: max-content;
      background: unset;
      /deep/ .el-menu-item,
      /deep/ .el-submenu__title {
        font-size: 18px;
        background: none;
      }
    }
  }
}

.phoneShow {
  display: none;
}

@media (max-width: 600px) {
  .phoneNav {
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    /deep/ .el-menu-item {
      // border-bottom: 0.5px solid @greyColor;
      background: linear-gradient(#0076bc, #015191);
    }
  }
  .phoneNone {
    display: none;
  }
  .phoneShow {
    display: block;
  }
  .companyTitle {
    font-size: 18px !important;
    letter-spacing: 0px !important;
  }
  .headerRoot {
    display: block;
    .NavRoot {
      width: 100%;
      background: white;
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1000;
    }
  }
}
</style>